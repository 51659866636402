import { mapActions, mapGetters } from 'vuex';

import { getQuestionnaireLocaleOptions } from '@/api/places';
import rootTypes from '@/store/types';

export const changeLanguageMixin = {
  computed: {
    ...mapGetters({
      locale: rootTypes.getters.GET_LOCALE
    }),
    languageOptions() {
      return getQuestionnaireLocaleOptions();
    }
  },
  methods: {
    ...mapActions({
      setLocale: rootTypes.actions.SET_LOCALE
    }),
    onChangeLanguage(locale) {
      this.setLocale(locale);
    }
  }
};

export default changeLanguageMixin;
