<template>
  <questionnaire-page-template
    :title="$t('selectYourDiagnostic.title')"
    :doctor-info="doctorInfo"
    :user-info="userInfo"
    :language-options="languageOptions"
    :language="language"
    no-footer
    no-divider
    content-centered
    basic-background
    @change-language="$emit('change-language', $event)"
  >
    <div class="select-your-diagnostic-page-content">
      <div
        class="select-your-diagnostic-page-content__text q-mt16 u-typography-helvetica u-text u-text--s"
      >
        {{ $t('selectYourDiagnostic.introText') }}
      </div>

      <img
        class="select-your-diagnostic-page-content__image"
        src="@/modules/questionnaire/assets/images/select-your-diagnostic/phone.gif"
      />

      <u-button
        class="select-your-diagnostic-page-content__button"
        size="small"
        @click="$emit('start-photo-analysis')"
        >{{ $t('selectYourDiagnostic.action.photoAssessment') }}</u-button
      >
      <u-button
        class="select-your-diagnostic-page-content__button"
        kind="secondary"
        size="small"
        @click="$emit('start-questionnaire')"
        >{{ $t('selectYourDiagnostic.action.questAssessment') }}</u-button
      >
    </div>
  </questionnaire-page-template>
</template>

<script>
import { UButton } from 'universkin-shared';

import QuestionnairePageTemplate from '@/modules/questionnaire/new-design-components/templates/QuestionnairePageTemplate';

export default {
  name: 'SelectYourDiagnosticTemplate',
  components: { QuestionnairePageTemplate, UButton },
  props: {
    doctorInfo: {
      type: Object,
      required: true
    },
    userInfo: {
      type: Object,
      required: true
    },
    languageOptions: {
      type: Array,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.select-your-diagnostic-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 490px;
  max-width: 100%;

  &__text {
    text-align: center;
    margin-bottom: 8px;
  }

  &__image {
    margin-bottom: 16px;
    height: 230px;
    max-width: 100%;
  }

  &__button {
    margin-bottom: 8px;
    width: 70%;
    min-width: 235px;
  }
}

@media (min-width: $desktop-start) {
  &__text {
    margin-bottom: 24px;
  }
}
</style>
