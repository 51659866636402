<template>
  <select-your-diagnostic-template
    :language="locale"
    :language-options="languageOptions"
    :doctor-info="doctorInfo"
    :user-info="patientInfo"
    @start-questionnaire="onStartQuiz"
    @start-photo-analysis="onStartPhotoAnalysis"
    @change-language="onChangeLanguage"
  />
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import SelectYourDiagnosticTemplate from '@/modules/questionnaire/pages/select-your-diagnostic/SelectYourDiagnosticTemplate';

import { changeLanguageMixin } from '@/modules/questionnaire/mixins/changeLanguageMixin';
import { doctorAndPatientInfoMixin } from '@/modules/questionnaire/mixins/doctorAndPatientInfoMixin';

import rootTypes from '@/store/types';
import { types as questTypes } from '@/modules/questionnaire/store/types';
import { QUESTIONNAIRE_TYPES } from '@/modules/questionnaire/api/constants';

export default {
  name: 'PageSelectYourDiagnostic',
  components: { SelectYourDiagnosticTemplate },
  mixins: [changeLanguageMixin, doctorAndPatientInfoMixin],
  methods: {
    ...mapMutations({
      setQuestionnaireType: questTypes.mutations.SET_QUESTIONNAIRE_TYPE
    }),
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      initQuestionnaire: questTypes.actions.INIT_QUESTIONNAIRE
    }),
    async onStartQuiz() {
      this.setQuestionnaireType(QUESTIONNAIRE_TYPES.STANDARD);

      this.setLoading(true);
      await this.initQuestionnaire();
      await this.$router.replace({ name: 'Questions' });
      this.setLoading(false);
    },
    onStartPhotoAnalysis() {
      this.setQuestionnaireType(QUESTIONNAIRE_TYPES.PHOTO_ANALYSIS);

      this.$router.replace({ name: 'LetsMakeASelfie' });
    }
  }
};
</script>
